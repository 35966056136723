import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeAttendance, addPoints, points_values } from './App';

export function KidsItem({ kid, setSelectedKid, setRefetch }) {

  function handleAttendanceUpdate() {
    if (kid.attendance && kid.attendance_id) {
      removeAttendance(kid.attendance_id, () => setRefetch(true));
    }
    else {
      addPoints(kid.id, 'attendance', () => setRefetch(true));
    }
  }

  return (
    <div className='flex justify-between p-3 col-span-2 border-gray-500 border-b-2'>
      <span className='capitalize' type='button' onClick={() => { handleAttendanceUpdate(); }}><span className={kid.attendance ? 'text-green-600' : 'text-gray-600'}><FontAwesomeIcon icon="fa-solid fa-flag" /></span> &nbsp; <span className='w-6 h-6 font-bold rounded-full bg-gray-800 text-gray-400 inline-block text-center'>{kid.name[0].toUpperCase()}</span> {kid.name}</span>
      <span><FontAwesomeIcon icon="fa-solid fa-bolt" className='text-yellow-300' /> {kid.points} &nbsp;
        <button
          onClick={e => setSelectedKid(kid)}
          className='bg-blue-400 px-2 rounded-md  text-white'>
          <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </button>
      </span>
    </div>);
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function SearchBar({ search, setSearch, setNewKid, setRefetch }) {
  return (<div className='flex gap-x-1 py-2'>
    <button onClick={()=>setNewKid(true)} className='p-2 rounded-md bg-blue-100 text-blue-500 hover:text-blue-600'><FontAwesomeIcon icon="fa-solid fa-user-plus" /></button>
    <div className=' border-2 rounded-md w-full flex flex-auto items-center'>
      <FontAwesomeIcon className='pl-2' icon="fa-solid fa-magnifying-glass" />
      <input type='text'
        value={search}
        onChange={e => {
          setSearch(e.target.value);
          if (e.target.value === '') setRefetch(true);
        }}
        className='p-2 flex-auto'
        placeholder='Search a kid' />
    </div>
  </div>);
}

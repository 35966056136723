
import { library } from '@fortawesome/fontawesome-svg-core'
import { faL, fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react';
import { KidsItem } from './KidsItem';
import { SearchBar } from './SearchBar';
import { DetailsModal } from './DetailsModal';

import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from './client';

library.add(fas)

export const points_values = {
  attendance: 10,
  memory_verse: 20,
  attentive: 10
}

async function signOut(callback=()=>{}) {
  const { error } = await supabase.auth.signOut();
  callback()
}

export async function addPoints(id,label="attendance",callback=()=>{}){
  let name, points;
  if(typeof(label) === 'string'){
    name = label
    points = points_values[label]
  }
  else{
    name = label.label
    points = label.points
  }

  try {
    const { data, error } = await supabase
      .from('points')
      .insert([
        { label: name, points: points, kid_id: id  }
      ]);

    if (error) {
      console.error(error);
    } else {
      callback()
    }
  } catch (error) {
    console.warn(error)
  }
}
export async function deductPoints(id,points,callback=()=>{}){

  try {
    const { data, error } = await supabase
      .from('points')
      .insert([
        { label: 'redeem', points: -points, kid_id: id  }
      ]);
    if (error) {
      console.error(error);
    } else {
      callback()
    }
  } catch (error) {
    console.warn(error)
  }
}
export async function removeAttendance(attendance_id, callback=()=>{}){
  try {
    const { data, error } = await supabase
      .from('points')
      .delete()
      .eq('id', attendance_id);

    if (error) {
      console.error(error);
    } else {
      callback()
    }
  } catch (error) {
    console.warn(error)
  }
}
export function kidsDataDesctructure(data){ // { data: data, date: date}
  // destructure data
  let kidsList = data.slice().map(d=>{
    let newObj  = { id: d.id, name: d.name, points: d.points[0]?.total_points || 0}
    const attendance_rec = d.kids.slice().filter(l=> l.label === 'attendance')[0]
    const lastAttendance = attendance_rec?.max || ''
    const attendance_id = attendance_rec?.attendance_id || null
    newObj = {...newObj, attendance: lastAttendance?.split('T')[0] === getCurrentDate(), attendance_id}
    return newObj
  })

  return kidsList
}
function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function App() {
  const [session, setSession] = useState(null)
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])


  return (
    <>
      <div style={{minHeight: 'calc(100vh - 60px)', }}>
        <div className='bg-gray-700'>
          <div className="container m-auto">
            <nav className="bg-gray-700 text-white p-5">
              <ul className='flex items-center gap-2'>
                <li>
                  <img src='/logo.png' width={50} height={50} alt="CDC Church Logo"/>
                </li>
                <li className='text-2xl font-bold'>CDC Kids Church</li>
              </ul>
            </nav>
          </div>
        </div>
        { session ? 
        <>
        <div className='bg-gray-800 px-2  py-3 text-right text-white hover:text-gray-200'>
          <div className='flex justify-between container m-auto'>
            <span className='inline-block pt-1'>{session.user.email}</span>
            <button className='cursor-pointer bg-gray-900 hover:bg-black py-1 px-3 rounded-md' onClick={e=> {
                  signOut(()=>{
                    setSession(null)
                  })
            }}> <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /> Logout</button>
          </div>
        </div>
        <MainApp />
        </>
        : 
          <div className='p-3 m-auto max-w-md'>
            <h1 className='text-xl pb-3 text-gray-600 text-center mt-10'><FontAwesomeIcon icon="fa-solid fa-user" /> Login</h1>
            <Auth
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa }}
              providers={['facebook']}
            />
          </div>
        }
      </div>
      <div className='bg-gray-800' style={{height: '60px'}}>
        <div className='container m-auto p-2'>
          <p className='text-gray-300 text-sm text-center'>Created by <a className='text-green-400 hover:text-green-500' href='https://bitslytech.com'>Bitsly I.T. Solutions.</a><br/><span className='text-xs'>CDC Kids Church App v1.0</span></p>
        </div>
      </div>
    </>
  )
}

function MainApp(){

  const [ kids, setKids ] = useState([])
  const [ selectedKid, setSelectedKid ] = useState(null)
  const [ search, setSearch ] = useState('')
  const [ refetch, setRefetch ] = useState(true)
  const [ date, setDate] = useState(getCurrentDate())
  const [ newKid, setNewKid ] = useState(false)
  const [ newKidName, setNewKidName ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  function handleCloseModal(){
    setSelectedKid(null)
  }

  useEffect(()=>{
    setIsLoading(true)
    async function fetchKidsList(){
      console.log("Fetching kids list")
      try {
        if(search || refetch){
          const { data, error} = await supabase
          .from('kids')
          .select(`
              id,
              name,
              created_at,
              points ( total_points:points.sum()),
              kids:points (label, created_at.max(), attendance_id:id.max())
          `)
          .ilike('name', `%${search}%`)
          .order('created_at', { ascending: false });;
            
          if (error) {
            console.warn(error);
            setKids([])
          }
          else{
            let kidsList = kidsDataDesctructure(data)
  
            setKids(kidsList)
          }
        }
        setRefetch(false)
      } catch (error) {
        console.warn(error)
      }
    }

    fetchKidsList()
    setIsLoading(false)
  },[search,refetch])

  async function addNewKid(name,callback=()=>{}){
    try {
      const { data, error } = await supabase
        .from('kids')
        .insert([
          { name: name }
        ]);

      if (error) {
        console.warn("Error inserting data:", error);
      } else {
        callback()
      }

    } catch (error) {
      console.warn(error)
    }
  }

  function handleAddNewKid(){
    setIsLoading(true)
    addNewKid(newKidName,()=>{
      setNewKidName('')
      setNewKid(false)
      setRefetch(true)
      setIsLoading(false)
      setSearch('')
    })
  }


  return (<>
    <div className='container m-auto p-2'>
    <h2 className='text-2xl'><FontAwesomeIcon icon="fa-solid fa-coins" /> Kids merit points</h2>
    {
      newKid ? 
      <div className='flex items-center  bg-gray-100 rounded-md my-2 border-2 border-white'>
        <span className=' rounded-l-md flex gap-x-1 items-center px-2'>👶</span>
        <input value={newKidName} onChange={e=>setNewKidName(e.target.value)} placeholder="Enter kid's name" className='p-2 bg-transparent  min-w-0 flex-auto ' type='text'  />
        <button style={{minWidth: '120px'}} disabled={newKidName ? false : true } onClick={()=>handleAddNewKid()} className={`p-2  text-white ${newKidName ? 'bg-blue-400 hover:bg-blue-500' : 'bg-gray-300'}`} >
          Add new kid
        </button>
        <button onClick={()=>setNewKid(false)} className='p-2 bg-transparent rounded-r-md text-gray'><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /></button>
      </div>
      : <SearchBar setNewKid={setNewKid} search={search} setSearch={setSearch} setRefetch={setRefetch}/>
    }
    <div className='hidden'>
    <label htmlFor="datepicker">Date:</label>
    <div className='border-2 rounded-md bg-gray-200 p-1'>
      <input type='date' id="datepicker" value={date} onChange={e=>setDate(e.target.value)} className='bg-gray-200 min-w-40 w-full md:w-auto'/>
    </div>
    </div>
    <div className='p-2 grid grid-cols-2 gap-y-1'>
      <div className='flex justify-between p-3 col-span-2'>
        <span className='font-bold text-blue-700'>Kid's Name</span>
        <span className='font-bold text-blue-700 pr-6'>Points</span>
      </div>
      { isLoading ? 
          <div className='flex justify-center col-span-2 mt-5'><div className='loader'/></div>
        : kids.map(kid=> <KidsItem kid={kid} setSelectedKid={setSelectedKid} setRefetch={setRefetch} key={kid.id}/>)}
    </div>
    </div>

    { selectedKid && <DetailsModal selectedKid={selectedKid} setRefetch={setRefetch} onCloseModal={handleCloseModal} /> }
    </>
  );
}

export default App;




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ModalTemplate, ModalHeader, ModalBody, ModalFooter } from './ModalTemplate';
import { supabase } from './client';
import { kidsDataDesctructure, removeAttendance, addPoints, deductPoints } from './App';

export function DetailsModal({ selectedKid, setRefetch, onCloseModal }) {
  const id = selectedKid.id;
  const [kidsInfo, setKidsInfo] = useState({});
  const [kidInfoRefetch, setKidInfoRefetch] = useState(true);
  const [customPoints, setCustomPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [redeeming, setRedeeming] = useState(false);
  const [redeemPoints, setRedeemPoints] = useState('');

  useEffect(() => {

    async function fetchKidData(id, callback = () => { }) {
      console.log("Fetching kids info");
      try {
        if (kidInfoRefetch) {
          const { data, error } = await supabase
            .from('kids')
            .select(`
              id,
              name,
              points ( total_points:points.sum()),
              kids:points (label, created_at.max() ,attendance_id:id.max())
          `)
            .eq('id', id);

          if (error) {
            console.warn(error);
            setKidsInfo({});
          }
          else {
            let kidsList = kidsDataDesctructure(data);
            setKidsInfo(kidsList[0]);
          }
        }
        setKidInfoRefetch(false);
      } catch (error) {
        console.warn(error);
      }
    }
    fetchKidData(id);
    setIsLoading(false);

  }, [kidInfoRefetch, id]);


  function handleAttendance() {
    setIsLoading(true);
    if (kidsInfo.attendance && kidsInfo.attendance_id) {
      removeAttendance(kidsInfo.attendance_id, () => {
        setKidInfoRefetch(true);
      });
    }
    else {
      addPoints(kidsInfo.id, 'attendance', () => {
        setKidInfoRefetch(true);
      });
    }
  }

  function handleMemoryVerse() {
    setIsLoading(true);
    addPoints(kidsInfo.id, 'memory_verse', () => {
      setKidInfoRefetch(true);
    });
  }

  function handleAttentive() {
    setIsLoading(true);
    addPoints(kidsInfo.id, 'attentive', () => {
      setKidInfoRefetch(true);
    });
  }

  function handleAddCustomPoints() {
    setIsLoading(true);
    addPoints(kidsInfo.id, { label: 'custom', points: customPoints }, () => { setKidInfoRefetch(true); });
    setCustomPoints(0);
  }

  function handleRedeemPoints() {
    if (window.confirm(`You are redeeming ${redeemPoints} points for ${kidsInfo.name}. The total points after you redeem is ${kidsInfo.points - redeemPoints}`)) {
      setIsLoading(true);
      deductPoints(kidsInfo.id, Number(redeemPoints), () => { setKidInfoRefetch(true); });
      setRedeemPoints('');
      setRedeeming(false);
    }
  }

  async function deleteChild(){
    try {
      if(window.confirm(`Are you sure you want to remove ${kidsInfo.name} from the list?`)){
        const { data, error } = await supabase
          .from('kids')
          .delete()
          .eq('id', kidsInfo.id);

        if (error) {
          console.error("Error deleting data:", error);
        } else {
          window.alert(`${kidsInfo.name} has been successfull removed.`)
          handleCloseModal()
        }
      }
    } catch (error) {
      console.warn(error)
    }
  }

  function handleCloseModal() {
    setRefetch(true);
    onCloseModal();
  }
  return (<ModalTemplate onCloseCallback={handleCloseModal}>
    <ModalHeader>
      <div className='p-4'>
        <p className='text-2xl font-bold capitalize'> <span className='w-10 h-10 pt-1 rounded-full bg-gray-800 text-gray-400 inline-block text-center'>{kidsInfo?.name?.substring(0,1)?.toUpperCase()}</span> {kidsInfo.name}</p>
      </div>
    </ModalHeader>
    <ModalBody>
      <div className='py-2'>
        <p className='text-center'>Total points:</p>
        <h1 style={{ height: '90px' }} className='text-center text-blue-700 text-5xl'>{isLoading ? <div className='flex justify-center'><div className="loader"></div></div> : kidsInfo.points}</h1>
      </div>
      <div className='p-4'>
        <p>Add more points:</p>
      </div>
      <div className='grid grid-cols-3 gap-x-2 px-4'>
        <div className='col-span-1'>
          <button onClick={e => handleAttendance()} className={` text-white rounded-md p-2 w-full h-full ${kidsInfo.attendance ? ' bg-gray-300 hover:bg-gray-400' : 'bg-green-600 hover:bg-green-700'}`}>
            <FontAwesomeIcon icon="fa-solid fa-flag" />
            <p>Attendance</p>
          </button>
        </div>
        <div className='col-span-1'>
          <button onClick={() => handleMemoryVerse()} className='bg-red-600 hover:bg-red-700 text-white rounded-md p-2 w-full h-full '>
            <FontAwesomeIcon icon="fa-solid fa-heart" />
            <p>Memory Verse</p>
          </button>
        </div>
        <div className='col-span-1'>
          <button onClick={() => handleAttentive()} className='bg-blue-600 hover:bg-blue-700 text-white rounded-md p-2 w-full h-full '>
            <FontAwesomeIcon icon="fa-solid fa-user-check" />
            <p>Attentive</p>
          </button>
        </div>
      </div>
      <div className='p-4 '>
        <label>Custom Points:</label>
        <div className='rounded-md flex bg-white p-1 '>
          <span className='flex items-center text-yellow-400 px-2'><FontAwesomeIcon icon="fa-solid fa-medal" /></span>
          <input className='py-2  min-w-0 bg-white flex-auto rounded-l-md' type='type' value={customPoints} onChange={e=>{
            setCustomPoints(p=>{
              return isNaN(e.target.value) ? p :
              e.target.value
            })
          }}/>
          
          <button className='text-white p-2 bg-blue-400 hover:bg-blue-500 w-auto ml-1'
            onClick={() => setCustomPoints(p => p > 0 ? p -= 10 : 0)}
          ><FontAwesomeIcon icon="fa-solid fa-minus" /></button>

          <button className='text-white p-2 bg-blue-400 hover:bg-blue-500 w-auto rounded-r-md ml-1'
            onClick={() => setCustomPoints(p => p += 10)}
          ><FontAwesomeIcon icon="fa-solid fa-plus" /></button>
          
          <button className='text-white p-2 bg-green-400 hover:bg-green-500 w-auto ml-2 rounded-md' onClick={() => handleAddCustomPoints()}> Add</button>
        </div>
      </div>
    </ModalBody>
    <ModalFooter className='flex justify-between'>

      {redeeming ?
        <div className='w-full bg-white flex justify-start rounded-md'>
          <span className='bg-white rounded-l-md flex gap-x-1 items-center px-2'><FontAwesomeIcon className='text-yellow-400' icon="fa-solid fa-bolt" /></span>
          <input placeholder='Enter points' className='p-2  min-w-0 bg-white flex-auto ' type='text' value={redeemPoints} onChange={e => setRedeemPoints(p => Number(e.target.value) || e.target.value === '' ? (Number(e.target.value) <= kidsInfo.points ? e.target.value : p) : p)} />
          <button disabled={redeemPoints ? false : true} onClick={() => handleRedeemPoints()} className={`${redeemPoints ? 'bg-blue-400 hover:bg-blue-500' : 'bg-gray-200'} text-white p-2 w-auto flex gap-x-1 items-center`}>
            <FontAwesomeIcon icon="fa-solid fa-cart-shopping" /> Redeem
          </button>
          <button onClick={()=>setRedeeming(false)} className='p-2 bg-transparent'><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /></button>
        </div> :
        <>
          <div>
            <button onClick={() => deleteChild()} className='rounded-md p-2 px-3 bg-red-700 hover:bg-red-800 text-white mr-1'><FontAwesomeIcon icon="fa-solid fa-user-xmark" /></button>
            <button onClick={() => setRedeeming(true)} className='rounded-md p-2 bg-teal-700 hover:bg-teal-800 text-white'><FontAwesomeIcon icon="fa-solid fa-cart-shopping" /> Redeem</button>
          </div>
          <button onClick={() => handleCloseModal()} className='rounded-md p-2 bg-green-600 hover:bg-green-700 text-white'><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Done</button>
        </>}

    </ModalFooter>
  </ModalTemplate>);
}

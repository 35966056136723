export function ModalTemplate({ className = '', onCloseCallback = () => { }, children = <></> }) {

  return (
    <div className={`fixed top-0 left-0 bottom-0 right-0 ${className}`}>
      <div type="button" onClick={() => onCloseCallback()} className='bg-gray-600 fixed inset-0 opacity-50 backdrop-blur-sm'></div>
      <div className='overflow-hidden fixed bottom-0 pb-16 left-0 right-0 lg:w-2/4 lg:max-w-3xl lg:top-0  lg:left-[unset] lg:right-0 lg:bottom-0 min-h-20 bg-gray-200 rounded-t-3xl lg:rounded-tr-none lg:rounded-l-2xl drop-shadow-2xl '>
        {children}
      </div>
    </div>);
}
export function ModalBody({ className = '', children }) {
  return (<div className={`${className}`}>
    {children}
  </div>);
}
export function ModalHeader({ className = '', children }) {
  return (<div className={`${className}`}>
    {children}
  </div>);
}
export function ModalFooter({ className, children }) {
  return (<div className={`py-2 px-4 bg-gray-700 mt-3 absolute w-full bottom-0 ${className}`}>
    {children}
  </div>);
}
